
:root {
    --background: #ffffff;
    --text-primary: #000;
    --text-secondary: #8e05c2;
    --accent: #8e05c2;
    --border: #333;
    --form-bg: #fff;
  }
  
  [data-theme='dark'] {
    --background: #000;
    --text-primary: #fff;
    --text-secondary: #8e05c2;
    --accent: #8e05c2;
    --border: #808080;
    --form-bg: #191919;
  }
  
  
  a {
    color: var(--text-secondary);
  }
  
.app {
  background-color: var(--background);
  color: var(--text-primary);
  /* height: 100vh; */
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: .8rem;
  transition: all .5s;
  font-family: 'Varela Round', sans-serif;
}
  
.login h1 {
  text-align: center;
  font-size: 48px;
  margin-top: 1rem;
}
  
.theme-toggle > h2 {
  text-align: center;
  padding: 1rem;
}
  
.theme-toggle i {
  font-size: 2rem;
  cursor: pointer;
}
  
.theme-toggle {
  text-align: center;
  margin-bottom: 2rem;
}
  
.login > .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  width: 400px;
  margin: 1rem auto 0;
  box-shadow: 0px 0px 8px var(--border);
  border-radius: 8px;
  background-color: var(--form-bg);
}

.container > .top {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.container > .top > * {
  font-size: 1.5rem;
  cursor: pointer;
}

.divider {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--text-primary);
  line-height: .1rem;
  margin: 2rem 0;
}

.divider > span {
  padding: 0 10px;
  background-color: var(--form-bg);
}

.container > form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container > form > label {
  margin: 8px 0;
  font-weight: 600;
}

.container > form > input {
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 6px;
  border: 1px solid var(--border);
  background: var(--background);
  color: var(--text-primary);
}

.remember {
  display: flex;
  align-items: center;
  width: 100%;
}

input[type=checkbox]:checked {
  margin: 0;
  padding: 0;
}

.remember p {
  padding-left: 8px;
}

.container > form > button {
  background-color: var(--accent);
  border: 1px solid var(--accent);
  color: #fff;
  margin: 16px 0;
  padding: 10px;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
}

.bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: .8rem;
}

.container > .create {
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 1rem;
  cursor: pointer;
  color: var(--text-primary);
  text-decoration: none;
}

@media screen and (max-width: 465px) {
  .login > .container {
    width: 350px;
  }
}

@media screen and (max-width: 420px) {
  .login > .container {
    width: 300px;
  }
}

@media screen and (max-width: 385px) {
  .login > .container {
    width: 260px;
  }
}

@media screen and (max-width: 325px) {
  .login > .container {
    width: 250px;
  }
}

@media screen and (max-width: 310px) {
  .login > .container {
    width: 240px;
    padding: .8rem;
  }

  .container > form > input {
    padding: 7px;
    margin-bottom: 8px;
  }

  .container > form > button {
    padding: 7px;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
  }
}