:root {
    --background: #ffffff;
    --text-primary: #000;
    --text-secondary: #8e05c2;
    --accent: #8e05c2;
    --border: #333;
    --form-bg: #fff;
    --gray-bg: #ccc;

}

[data-theme='dark'] {
    --background: #000;
    --text-primary: #fff;
    --text-secondary: #8e05c2;
    --accent: #8e05c2;
    --border: #808080;
    --form-bg: #191919;
    --gray-bg: #333;
}

.footer {
    background-color: var(--background);
    color: var(--text-primary);
    width: 100%;
    border-top: 0.05px solid var(--text-primary);
    font-family: 'Varela Round', sans-serif;
    padding: 30px 0;
    /* position: absolute;
    bottom: 0;
    left: 0; */
}

.footer-list ul {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.footer-list ul li {
    font-size: 1.45rem;
}

.footer-list ul li:not(:last-of-type) {
    font-size:1.6rem;
}

.footer-list ul li a {
    text-decoration: none;
    color: var(--text-primary);
}

.footer-copyright {
    text-align: center;
    margin-top: 18px;
    font-size: 1.1rem;
    letter-spacing: 1px;

}

@media screen and (max-width: 768px) {
    .footer-list ul li {
        font-size: 1rem;
        margin-left: 20px;
    }
    .footer-list ul li:not(:last-of-type) {
        font-size:1.2rem;
    }
    .footer-copyright {
        font-size: .9rem;
    }
}