:root {
  --background: #ffffff;
  --text-primary: #000;
  --text-secondary: #8e05c2;
  --accent: #8e05c2;
  --border: #333;
  --form-bg: #fff;
}

[data-theme='dark'] {
  --background: #000;
  --text-primary: #fff;
  --text-secondary: #8e05c2;
  --accent: #8e05c2;
  --border: #808080;
  --form-bg: #191919;
}


.register-wrapper {
  background-color: var(--background);
  color: var(--text-primary);
  /* height: 100vh; */
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: .8rem;
  transition: all .5s;
  font-family: 'Varela Round', sans-serif;
  padding-bottom: 30px;
}

.register h1 {
  text-align: center;
  margin-top: 1rem;
  font-size: 40px;
}

.register > .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  width: 400px;
  margin: 1.4rem auto 0;
  box-shadow: 0px 0px 8px var(--border);
  border-radius: 8px;
  background-color: var(--form-bg);
}

.register > .container > .top {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.register > .container > .top > * {
  font-size: 1.5rem;
  cursor: pointer;
}

.register > .container > .divider {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--text-primary);
  line-height: .1rem;
  margin: 2rem 0;
}

.register > .container > .divider > span {
  padding: 0 10px;
  background-color: var(--form-bg);
}

.register > .container > form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.register > .container > form > label {
  margin: 8px 0;
  font-weight: 600;
}

.register > .container > form > input {
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 6px;
  border: 1px solid var(--border);
  background: var(--background);
  color: var(--text-primary);
}

.register > .container > .remember {
  display: flex;
  align-items: center;
  width: 100%;
}

input[type=checkbox]:checked {
  margin: 0;
  padding: 0;
}

.remember p {
  padding-left: 8px;
}

.register > .container > form > button {
  background-color: var(--accent);
  border: 1px solid var(--accent);
  color: #fff;
  margin: 16px 0;
  padding: 10px;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
}

.register > .container > .register-footer > p  {
  text-align: center;
}

.register > .container > .register-footer > p:first-child {
  color: var(--text-secondary);
  margin-bottom: 5px;
  font-size: .8rem;
  cursor: pointer;
}

.register > .container > .register-footer > p:nth-child(2) > span { 
  color: var(--text-secondary);
  cursor: pointer;
}

  
@media screen and (max-width: 465px) {
  .register > .container {
    width: 350px;
  }
}

@media screen and (max-width: 420px) {
  .register > .container {
    width: 300px;
  }
}

@media screen and (max-width: 385px) {
  .register > .container {
    width: 260px;
  }
}

@media screen and (max-width: 325px) {
  .register > .container {
    width: 240px;
  }
}

@media screen and (max-width: 280px) {
  .register > .container {
    width: 220px;
  }
}